"use client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import GenericModal from "../modals/GenericModal";
import { AnimatePresence } from "framer-motion";
const url = process.env.NEXT_PUBLIC_BASE_URL;
import { motion } from "framer-motion";
import ToggleWishList from "./ToggleWishList";
import ImageComponent from "next/image";
const Card = ({
  property,
  previewImages,
  availability = null,
  lowestPrice = 0,
  important = false,
  loading = "lazy",
}) => {
  const [showShare, setShowShare] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const { propertyName, _id, area, city } = property;

  const nextImage = () => {
    setPreviewIndex((prev) => {
      return prev === previewImages.length - 1 ? 0 : prev + 1;
    });
  };

  const prevImage = () => {
    setPreviewIndex((prev) =>
      prev === 0 ? previewImages.length - 1 : prev - 1
    );
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(
        `${url}/Hotel/${propertyName
          .toString()
          .toLowerCase()
          .trim()
          .replace(/\s+/g, "-")}?id=${_id}`
      )
      .then(() => {
        toast.success(`Link copied to the clipboard`);
      });
  };

  const handleShareHotel = () => {
    const propertyLink = `${url}/Hotel/${propertyName
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")}?id=${_id}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Check out this property: ${propertyName}`,
          url: propertyLink,
          text: "I found this amazing property!",
        })
        .catch((err) => {
          console.error("Failed to share:", err);
        });
    } else {
      navigator.clipboard
        .writeText(propertyLink)
        .then(() => {
          alert("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <>
      <AnimatePresence>
        {showShare && (
          <GenericModal onClose={setShowShare}>
            <div className="share-modal">
              <h4>Share the property</h4>
              <div className="property">
                <ImageComponent
                  src={previewImages?.[0] ?? null}
                  alt="property"
                  width={200}
                  height={200}
                  priority={important}
                />
                <h3>{propertyName}</h3>
              </div>

              <div className="share-buttons">
                <button className="cancel-button" onClick={handleCopy}>
                  Copy Link
                </button>
                <button className="cancel-button" onClick={handleShareHotel}>
                  Share
                </button>
              </div>
            </div>
          </GenericModal>
        )}
      </AnimatePresence>
      <div className="relative card">
        <button
          onClick={(e) => {
            e.preventDefault();
            prevImage();
          }}
          className="left-button"
        >
          <ImageComponent
            src="/assets/icons/svgs/angle-left.svg"
            width={5}
            height={10}
            alt="share"
          />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            nextImage();
          }}
          className="right-button"
        >
          <ImageComponent
            src="/assets/icons/svgs/angle-right.svg"
            width={5}
            height={10}
            alt="share"
          />
        </button>
        <a
          className="card-link"
          href={`/Hotel/${propertyName
            .toString()
            .toLowerCase()
            .trim()
            .replace(/\s+/g, "-")}?id=${_id}`}
        >
          <div className="card-image">
            <motion.div
              key={previewImages?.[previewIndex]}
              initial={{ filter: "brightness(50%)" }}
              animate={{
                filter: "brightness(100%)",
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
              style={{ height: "100%" }}
            >
              <ImageComponent
                src={previewImages?.[previewIndex] ?? null}
                loading={loading}
                alt="Hotel Image"
                width={300}
                height={300}
                objectFit="cover"
                className="hotel-image"
              />
            </motion.div>
            <div className="card-icons" onClick={(e) => e.preventDefault()}>
              <span
                className="icon"
                onClick={(e) => {
                  e.preventDefault();
                  setShowShare(true);
                }}
              >
                <ImageComponent
                  src="/assets/icons/svgs/share.svg"
                  width={16}
                  height={16}
                  alt="share"
                />
              </span>
              <span onClick={(e) => e.preventDefault()}>
                <ToggleWishList _id={_id} />
              </span>
            </div>
          </div>
          <div className="card-content">
            <div className="title">
              <h4>{propertyName}</h4>
              <hr />
            </div>
            <div>
              <p className="sm">
                {area}, {city}
              </p>

              {lowestPrice ? (
                <h5 className="lowest-price">
                  starting from:{" "}
                  {lowestPrice ? Number(lowestPrice).toLocaleString() : ""} AED
                </h5>
              ) : null}
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Card;
